import React, { useEffect, useState } from "react";
import { fetchBlogs } from "../../services/blog_service";
import BlogItem from "./BlogItem";
import Loader from "../utils/Loader";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogLoading, setBlogLoading] = useState(true);

  async function getBlogs(params) {
    const data = await fetchBlogs();
    setBlogs(data);
    setBlogLoading(false);
  }

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      {blogLoading ? (
        <Loader />
      ) : blogs && blogs.length > 0 ? (
        <div className="grid sm:grid-cols-2 justify-items-center md:justify-items-start  md:grid-cols-3 h-fit gap-8">
          {blogs.map((item, index) => (
            <BlogItem key={index} data={item} />
          ))}
        </div>
      ) : (
        <div className="text-sm p-36 text-center w-full">Bloq yoxdur!</div>
      )}
    </>
  );
};

export default BlogList;
