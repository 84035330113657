import { createContext, useState, useContext, useEffect } from "react";
import {
  fetchCategories,
  fetchSubcategories,
} from "../services/category_service";

const Context = createContext();

export const SiteProvider = ({ children }) => {
  const [productsOnCart, setProductsOnCart] = useState(
    JSON.parse(localStorage.getItem("cartItem")) || []
  );
  const [productsOnFavorite, setProductsOnFavorite] = useState(
    JSON.parse(localStorage.getItem("favItem")) || []
  );

  const [totalCart, setTotalCart] = useState(
    JSON.parse(localStorage.getItem("cartItem"))?.reduce(
      (acc, value) => acc + value.data.NET * value.count,
      0
    ) || 0
  );

  const [cartCount, setCartCount] = useState(
    JSON.parse(localStorage.getItem("cartItem"))?.reduce(
      (acc, value) => acc + value.count,
      0
    ) || 0
  );

  const [favCount, setFavCount] = useState(
    JSON.parse(localStorage.getItem("favItem"))?.length || 0
  );
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);

  async function getSubcategories() {
    const data = await fetchSubcategories(0);
    setSubcategories(data);
  }
  async function getCategories() {
    const data = await fetchCategories(0);
    setCategories(data);
  }

  useEffect(() => {
    getCategories();
    getSubcategories();
  }, []);

  const data = {
    productsOnCart,
    setProductsOnCart,
    productsOnFavorite,
    setProductsOnFavorite,
    cartCount,
    setCartCount,
    favCount,
    setFavCount,
    totalCart,
    setTotalCart,
    categories,
    subcategories,
    mobileNavIsOpen,
    setMobileNavIsOpen,
    orderLoading,
    setOrderLoading,
  };

  return <Context.Provider value={data}>{children}</Context.Provider>;
};

export const useSite = () => useContext(Context);
