import React, { useState } from "react";
import LinkItem from "./LinkItem";
import MenuDropdown from "./MenuDropdown";
import { Link, useNavigate } from "react-router-dom";
import { useSite } from "../../context/SiteContext";

const Navbar = () => {
  const navigate = useNavigate();
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [dropdownSize, setDropdownSize] = useState("small");
  const [searchValue, setSearchValue] = useState(null);
  const { cartCount, favCount, subcategories, categories, setMobileNavIsOpen } =
    useSite();

  const handleMouseEnter = (id) => {
    setHoveredCategory(subcategories.filter((item) => item.CATEGORY_ID === id));
    id === 4 ? setDropdownSize("large") : setDropdownSize("small");
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
  };

  function handleSearch() {
    if (searchValue) {
      navigate(`/products/search?q=${searchValue}`);
    }
  }
  return (
    <nav className="flex flex-col">
      <div className="flex justify-between items-center relative">
        <img
          src={"/menu.svg"}
          alt="menu"
          className="cursor-pointer w-7 h-auto lg:hidden block"
          onClick={() => setMobileNavIsOpen(true)}
        />
        <ul className="hidden gap-8 text-sm text-[#003F25] lg:flex">
          {categories &&
            categories.map((item) =>
              item.ID === 4 ? (
                ""
              ) : (
                <li key={item.ID}>
                  <LinkItem
                    key={item.ID}
                    title={item.NAME}
                    handleMouseEnter={() => handleMouseEnter(item.ID)}
                  />
                </li>
              )
            )}
          <Link
            key={"blog"}
            to={"/blogs"}
            className="font-bold hover:border-b-2 pb-1 border-[#003F25] capitalize"
          >
            Bloq
          </Link>
        </ul>
        {hoveredCategory ? (
          <MenuDropdown
            menuItems={hoveredCategory}
            hoverOutHandle={handleMouseLeave}
            size={dropdownSize}
          />
        ) : (
          ""
        )}
        <div className="flex gap-5 ">
          <div className="relative flex">
            <Link to={"/favorites"}>
              <img
                src={"/heart.svg"}
                alt="cart"
                className="cursor-pointer w-7 h-auto"
              />
            </Link>

            {favCount > 0 ? (
              <span className=" text-xs bg-slate-300 rounded-full absolute -right-1 px-1">
                {favCount}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="relative flex">
            <Link to={"/cart"}>
              <img
                src={"/cart.svg"}
                alt="cart"
                className="cursor-pointer w-7 h-auto"
              />
            </Link>

            {cartCount > 0 ? (
              <span className=" text-xs bg-slate-300 rounded-full absolute -right-1 px-1">
                {cartCount}
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="relative hidden sm:flex w-fit">
            <input
              type="text"
              className="block w-52 px-2 py-1.5 text-gray-900 border border-gray-100 rounded-sm bg-gray-50 text-sm focus:border-slate-300 outline-none transition-all"
              placeholder="Axtar"
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSearch();
                }
              }}
            />
            <img
              src={"/search.svg"}
              alt="cart"
              className="absolute right-1 top-[2px] w-7 h-auto"
              onClick={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="relative flex sm:hidden w-full mt-6">
        <input
          type="text"
          className="block w-full px-2 py-2 text-gray-900 border border-gray-100 rounded-sm bg-gray-50 text-sm focus:border-slate-300 outline-none transition-all"
          placeholder="Axtar"
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
        />
        <img
          src={"/search.svg"}
          alt="cart"
          className="absolute right-1 top-[2px] w-7 h-auto"
        />
      </div>
    </nav>
  );
};

export default Navbar;
