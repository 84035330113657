import { useState } from "react";
import MobileDropdown from "./MobileDropdown";
import { useSite } from "../../context/SiteContext";
import clsx from "clsx";

const MobileNav = () => {
  const [dropdowns, setDropdowns] = useState({});
  const { categories, subcategories, setMobileNavIsOpen, mobileNavIsOpen } =
    useSite();

  const toggleDropdown = (index) => {
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [index]: !prevDropdowns[index],
    }));
  };

  return (
    <div
      className={clsx(
        "transition-transform duration-300 ease-in-out transform fixed h-screen top-0 left-0 py-6 px-4",
        {
          "-translate-x-full": !mobileNavIsOpen,
          "translate-x-0 w-full bg-white z-10": mobileNavIsOpen,
        }
      )}
    >
      <div className="flex justify-center">
        <img
          src="/close.svg"
          alt="close"
          width={30}
          className="position absolute left-5 top-8 opacity-50 cursor-pointer"
          onClick={() => setMobileNavIsOpen(false)}
        />
        <img src="/logo.svg" alt="logo" width={200} />
      </div>
      <div className="flex flex-col gap-5 mt-10">
        {categories &&
          categories.map((item) =>
            item.ID !== 4 ? (
              <MobileDropdown
                key={item.ID}
                id={item.ID}
                label={item.NAME}
                subcategories={subcategories}
                isOpenDropdown={dropdowns[item.ID]}
                toggle={() => toggleDropdown(item.ID)}
              />
            ) : (
              ""
            )
          )}
      </div>
    </div>
  );
};

export default MobileNav;
