import axios from "axios";
import { toast } from "react-toastify";

export async function fetchPostOrder(data, token) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/invoices/orders`,
      {
        data,
        type: 4,
        token,
      }
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data);
    return false;
  }
}
