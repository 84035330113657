import axios from "axios";

export async function fetchCategories(status) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/categories`,
      {
        params: {
          status,
        },
      }
    );
    return response.data;
  } catch (error) {}
}
export async function fetchSubcategories(status) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/categories/sub`,
      {
        params: {
          status,
        },
      }
    );
    return response.data;
  } catch (error) {}
}
