import React, { useEffect, useState } from "react";
import SectionTitle from "./SectionTitle";
import Product from "../Product/Product";
import Button from "../utils/Button";
import { fetchProductsByCategory } from "../../services/product_service";
import slugName from "../utils/slugName";
import { useNavigate } from "react-router-dom";

const CaruselSection = ({ title, category }) => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  async function getData() {
    const data = await fetchProductsByCategory(category);
    setProducts(data);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="mt-8 border-b-slate-200 border-b pb-10">
      <div className="container">
        <SectionTitle title={title} />
        {products && products.length > 0 ? (
          <div>
            <div className="flex gap-10 justify-center mt-4 flex-wrap">
              {products.map((item) => (
                <Product key={item.ID} data={item} />
              ))}
            </div>
            <div className="flex justify-center mt-10">
              <Button
                type={"more"}
                title={"daha çox"}
                handleClick={() => {
                  navigate(`/categories/${slugName(title)}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="p-10 text-center font-bold text-slate-500">
            TEZLİKLƏ
          </div>
        )}
      </div>
    </section>
  );
};

export default CaruselSection;
