import { useParams } from "react-router-dom";

const OrderPage = () => {
  const { code } = useParams();

  return (
    <div className="container">
      <div className="flex flex-col items-center gap-2 pt-10 pb-16">
        <img
          src={"/success.svg"}
          alt="cart"
          className="sm:w-[150px] sm:h-auto w-[80px]"
        />
        <p className="text-[#003F25] text-lg sm:text-2xl font-bold text-center">
          {code.toUpperCase()} nömrəli sifarişiniz qeydə alındı!
        </p>
        <p className="text-slate-600 text-sm">
          Yaxın zamanda sizinlə əlaqə saxlanılacaq
        </p>
      </div>
    </div>
  );
};

export default OrderPage;
