import { Link } from "react-router-dom";
import Navbar from "../Nav/Navbar";
import MobileNav from "../Nav/MobileNav";
import { useSite } from "../../context/SiteContext";

const Header = () => {
  const { mobileNavIsOpen } = useSite();
  return (
    <div className="pt-10 flex flex-col items-center">
      <div className="flex justify-center container">
        <Link to={"/"}>
          <img src={"/logo.svg"} alt="logo" className="w-64 h-auto" />
        </Link>
      </div>
      <div className="pt-10 pb-6 sm:py-10 container">
        <Navbar />
        <MobileNav />{" "}
      </div>
    </div>
  );
};

export default Header;
