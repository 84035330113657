import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "animate.css";
import "@splidejs/react-splide/css";

import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./layout/Layout";
import CartPage from "./pages/CartPage";
import FavoritePage from "./pages/FavoritePage";
import OrderPage from "./pages/OrderPage";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import SubCategoryPage from "./pages/SubCategoryPage";
import SearchPage from "./pages/SearchPage";
import CategoryPage from "./pages/CategoryPage";
import BlogPage from "./pages/BlogPage";
import BlogDetailPage from "./pages/BlogDetailPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RootLayout>
        <App />
      </RootLayout>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "cart",
        element: <CartPage />,
      },
      {
        path: "favorites",
        element: <FavoritePage />,
      },
      {
        path: "orders/:code",
        element: <OrderPage />,
      },
      {
        path: "products/search",
        element: <SearchPage />,
      },
      {
        path: "products/:url",
        element: <ProductPage />,
      },
      {
        path: "categories/:parent",
        element: <CategoryPage />,
      },
      {
        path: "categories/:parent/:sub",
        element: <SubCategoryPage />,
      },
      {
        path: "blogs",
        element: <BlogPage />,
      },
      {
        path: "blogs/:url",
        element: <BlogDetailPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
