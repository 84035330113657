import { useSearchParams } from "react-router-dom";

const ProductOrder = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex h-fit flex-col">
      <select
        id="order"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1 outline-none"
        defaultValue={"order"}
        onChange={(e) => {
          const params = Object.fromEntries([...searchParams]);
          params.order = e.target.value;
          setSearchParams(params);
        }}
      >
        <option value={"order"}>Ən çox satanlar</option>
        <option value={"to-high"}>Qiymət, aşağıdan yuxarıya</option>
        <option value={"to-low"}>Qiymət, yuxarıdan aşağıya</option>
        <option value={"old"}>Tarix, köhnədən yeniyə</option>
        <option value={"new"}>Tarix, yenidən köhnəyə</option>
      </select>
    </div>
  );
};

export default ProductOrder;
