import { HelmetProvider } from "react-helmet-async";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <HelmetProvider>
      <main>
        <Outlet />
      </main>
    </HelmetProvider>
  );
}

export default App;
