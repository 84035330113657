import BlogSidebar from "../components/Blog/BlogSidebar";
import BlogList from "../components/Blog/BlogList";
import SEO from "../components/utils/SEO";

const BlogPage = () => {
  return (
    <div className="container mb-16">
      <SEO title={"Bloqlar"} />

      <div className="flex justify-between items-center py-10 sm:flex-row flex-col gap-5 sm:gap-0">
        <h3 className="font-bold text-2xl">Bloqlar</h3>
      </div>
      <div className="flex gap-14 relative justify-center lg:flex-row flex-col">
        <BlogList />
        <BlogSidebar />
      </div>
    </div>
  );
};

export default BlogPage;
