import axios from "axios";

export async function fetchProducts() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products`
    );
    return response.data;
  } catch (error) {}
}

export async function fetchProductsBySearch(value) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/site/search?q=${value}`
    );
    return response.data;
  } catch (error) {}
}

export async function fetchProductsByCategory(name) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/category`,
      {
        params: {
          name,
        },
      }
    );
    return response.data;
  } catch (error) {}
}

export async function fetchProductBySubcategory(id) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/subcategory`,
      {
        params: {
          id,
        },
      }
    );
    return response.data;
  } catch (error) {}
}

export async function fetchProductBySubcategoryMerge(name) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/subcategory/merge`,
      {
        params: {
          name,
        },
      }
    );
    return response.data;
  } catch (error) {}
}

export async function fetchProductByStone(names, id) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/stone`,
      {
        params: {
          stones: names,
          subcategoryId: id,
        },
      }
    );
    return response.data;
  } catch (error) {}
}

export async function fetchProductByStoneAndSubcategoryName(stoneId, name) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/stone`,
      {
        params: {
          stones: stoneId,
          subcategoryName: name,
        },
      }
    );
    return response.data;
  } catch (error) {}
}

export async function fetchProductBySlug(slug) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/${slug}`
    );
    return response.data;
  } catch (error) {}
}
