import lodash from "lodash";

export default function slugName(text) {
  text = lodash
    .replace(text, new RegExp("ə", "g"), "e")
    .replace(new RegExp("ç", "g"), "c")
    .replace(new RegExp("ü", "g"), "u")
    .replace(new RegExp("ö", "g"), "o")
    .replace(new RegExp("ğ", "g"), "g")
    .replace(new RegExp("ş", "g"), "s")
    .replace(new RegExp("ı", "g"), "i");
  text = lodash.kebabCase(text);
  return text;
}
