import React from "react";
import { Link } from "react-router-dom";

const BlogSidebarItem = ({ data }) => {
  return (
    <div className="w-full lg:w-60 animate__animated animate__fadeIn">
      <Link to={`/blogs/${data.SEO_URL}`}>
        <div
          style={{
            backgroundImage: `url(${data.IMAGE_PATH})`,
          }}
          className="sm:w-[250px] w-full h-[250px] sm:h-[150px] my-4 rounded bg-cover bg-center"
        ></div>
        <h5 className="text-sm">{data.NAME}</h5>
      </Link>
      <p className="text-xs text-[#999b9c]">
        {new Date(data.DATE).toLocaleDateString()}
      </p>
    </div>
  );
};

export default BlogSidebarItem;
