const Button = ({ title, type, handleClick }) => {
  let customClass;
  switch (type) {
    case "primary":
      customClass =
        "capitalize text-xs text-white bg-[#003F25] px-3 py-1 font-semibold";
      break;
    case "secondary":
      customClass =
        "capitalize text-xs border border-[#003F25] text-[#003F25] px-3 py-1 font-semibold";
      break;
    case "more":
      customClass = "capitalize text-md text-white bg-[#003F25] px-3 py-1 ";
      break;
    default:
      break;
  }
  return (
    <button className={customClass} onClick={handleClick}>
      {title}
    </button>
  );
};

export default Button;
