import React from "react";
import { useSite } from "../context/SiteContext";
import { Link } from "react-router-dom";
import CartProduct from "../components/Product/CartProduct";
import OrderForm from "../components/Form/OrderForm";
import SEO from "../components/utils/SEO";

const CartPage = () => {
  const { cartCount, productsOnCart, orderLoading } = useSite();
  return (
    <div className="container px-10 mb-10">
      <SEO title={"Səbət"} />

      {productsOnCart.length > 0 ? (
        <>
          <div className="flex gap-5 text-[#003F25] pb-5">
            <h3 className="font-bold text-2xl">Səbət</h3>
            <p className="text-xs self-end">
              Səbətinizdə {cartCount} məhsul var
            </p>
          </div>

          <div className="flex gap-5 flex-col lg:flex-row relative">
            {orderLoading ? (
              <div className="flex items-center justify-center w-full h-full  rounded-lg bg-transparent backdrop-blur-sm absolute z-10">
                <div className="px-3 py-2 text-sm font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse ">
                  Sifariş yaradılır...
                </div>
              </div>
            ) : null}

            <div className="bg-slate-50 p-10 lg:w-2/3 w-full flex flex-col gap-5">
              {productsOnCart.map((item, index) => (
                <CartProduct key={index} item={item} />
              ))}
            </div>
            <div className="lg:w-1/3 w-full bg-slate-50 p-10 flex flex-col justify-between">
              <div className="flex gap-5 items-center mb-10">
                <img
                  src={"/delivery.svg"}
                  className="w-10 h-auto"
                  alt="delivery"
                />
                <p className="text-sm">Şəhərdaxili çatdırılma pulsuzdur</p>
              </div>
              <OrderForm />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-5 text-[#003F25] pb-5">
            <h3 className="font-bold text-2xl">Səbət</h3>
          </div>
          <div className="flex flex-col items-center justify-center gap-8 pt-10 pb-16">
            <div className="flex flex-col items-center gap-2">
              <img
                src={"/cart.svg"}
                alt="cart"
                className="opacity-10 w-24 h-auto"
              />
              <p className="font-bold">Səbət boşdur</p>
              <p className="text-slate-400">
                Sizə lazım olan məhsulları tapmağınız üçün alış-verişə davam
                edin
              </p>
            </div>

            <Link
              className="capitalize text-sm text-white bg-[#003F25] px-3 py-3 font-semibold"
              to={"/"}
            >
              Alış-verişə davam
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default CartPage;
