import { useEffect, useState } from "react";
import Product from "./Product";
import { useParams, useSearchParams } from "react-router-dom";
import {
  fetchProductByStone,
  fetchProductByStoneAndSubcategoryName,
} from "../../services/product_service";
import { useSite } from "../../context/SiteContext";
import { fetchSubcategories } from "../../services/category_service";

const ProductList = ({ products, subcat, type }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const [filteredProducts, setFilteredProducts] = useState(products);
  const { subcategories } = useSite();

  async function getFilteredProducts(stones) {
    console.log(type);

    if (type === "sub") {
      const data = await fetchProductByStone(stones, subcat.ID);
      setFilteredProducts(data);
    } else {
      const data = await fetchProductByStoneAndSubcategoryName(
        stones,
        params.parent
      );
      setFilteredProducts(data);
    }
  }

  useEffect(() => {
    if (subcategories.length > 0) {
      const stones = searchParams.get("stone")?.split(",");
      const order = searchParams.get("order");
      let filteredProductsCopy = [...products];

      if (stones) {
        const arr = [];
        for (const iterator of stones) {
          const st = subcategories.find(
            (item) => item.SUBCATEGORY === iterator
          );
          arr.push(st.ID);
        }
        getFilteredProducts(arr);
      } else {
        setFilteredProducts(filteredProductsCopy);
      }

      if (order === "to-high") {
        const sorted = filteredProductsCopy.sort((a, b) => a.NET - b.NET);
        setFilteredProducts(sorted);
      }

      if (order === "to-low") {
        const sorted = filteredProductsCopy.sort((a, b) => b.NET - a.NET);
        setFilteredProducts(sorted);
      }

      if (order === "old") {
        const sorted = filteredProductsCopy.sort(
          (a, b) => new Date(a.DATE) - new Date(b.DATE)
        );
        setFilteredProducts(sorted);
      }

      if (order === "new") {
        const sorted = filteredProductsCopy.sort(
          (a, b) => new Date(b.DATE) - new Date(a.DATE)
        );
        setFilteredProducts(sorted);
      }
    }

    // if (maxPrice) {
    //   filtered = filtered.filter(product => product.price <= parseInt(maxPrice, 10));
    // }

    // setFilteredProducts(filtered);
  }, [searchParams, subcategories]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  return (
    <>
      {filteredProducts.length > 0 ? (
        <div className="flex flex-wrap gap-4 justify-center md:justify-normal">
          {filteredProducts.map((item) => (
            <Product size="small" data={item} key={item.ID} />
          ))}
        </div>
      ) : (
        <div className="flex justify-center w-full items-center min-h-96">
          <p className="text-slate-600">Məhsul tapılmadı</p>
        </div>
      )}
    </>
  );
};

export default ProductList;
