import React, { useEffect, useState } from "react";
import { fetchRecommedBlogs } from "../../services/blog_service";
import BlogSidebarItem from "./BlogSidebarItem";
import Loader from "../utils/Loader";

const BlogSidebar = () => {
  const [recomLoading, setRecomLoading] = useState(true);
  const [recommedBlogs, setRecommedBlogs] = useState([]);

  async function getRecommedBlogs(params) {
    const data = await fetchRecommedBlogs();
    setRecommedBlogs(data);
    setRecomLoading(false);
  }

  useEffect(() => {
    getRecommedBlogs();
  }, []);

  return (
    <div className="flex flex-col gap-6 sticky top-6 h-full">
      <div className="bg-slate-100 p-6 rounded-md min-w-60">
        <h4 className="mb-4 text-lg font-semibold border-b border-b-slate-200">
          Önərilən bloqlar
        </h4>
        {recomLoading ? (
          <Loader />
        ) : recommedBlogs && recommedBlogs.length > 0 ? (
          <ul className="flex flex-col gap-3 items-center lg:items-start">
            {recommedBlogs.map((item, index) => (
              <li key={index} className="w-full sm:w-fit">
                <BlogSidebarItem key={index} data={item} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-sm p-36 text-center w-full">Bloq yoxdur!</div>
        )}
      </div>
    </div>
  );
};

export default BlogSidebar;
