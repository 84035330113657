import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useSite } from "../../context/SiteContext";
import { fetchPostOrder } from "../../services/order_service";
import { fetchProductImages } from "../../services/image_service";
import { fetchSendOrderEmail } from "../../services/email_service";

const OrderNowModal = ({ isOpen, product, setIsOpen }) => {
  const { orderLoading, setOrderLoading } = useSite();
  const [productImages, setProductImages] = useState([]);

  const [count, setCount] = useState(1);
  const navigate = useNavigate();

  async function submitOrder(e) {
    e.preventDefault();
    setOrderLoading(true);
    const form = new FormData(e.target);
    const order = {
      products: [
        {
          data: product,
          count,
        },
      ],
      fullname: form.get("name"),
      phone: "+994" + form.get("phone"),
      note: form.get("note"),
    };
    const res = await fetchPostOrder(order, process.env.REACT_APP_ORDER_TOKEN);
    if (res) {
      await fetchSendOrderEmail({
        name: order.fullname,
        phone: order.phone,
        code: res,
        note: order.note,
        total: product.NET * count,
        products: order.products,
        token: process.env.REACT_APP_ORDER_TOKEN,
      });
      setTimeout(() => {
        setOrderLoading(false);
        setIsOpen(false);
        navigate(`/orders/${res}`);
      }, 1500);
    } else {
      setOrderLoading(false);
    }
  }

  async function getProductImages() {
    const data = await fetchProductImages();
    const current = data.filter((pr) => pr.PRODUCT_ID === product.ID);
    setProductImages(current);
  }

  useEffect(() => {
    if (isOpen) {
      getProductImages();
    }
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      className={
        "absolute inset-10 overflow-y-scroll outline-none bg-white p-5 md:w-2/3 w-4/5 max-h-screen mx-auto border border-slate-300"
      }
    >
      <div className="relative">
        <h4 className="font-bold text-2xl pb-10">Sifariş</h4>
        <form onSubmit={submitOrder} className="mb-10">
          {orderLoading ? (
            <div className="flex items-center justify-center w-full h-full  rounded-lg bg-transparent backdrop-blur-sm absolute z-10">
              <div className="px-3 py-2 text-sm font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse ">
                Sifariş yaradılır...
              </div>
            </div>
          ) : null}
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="text"
              name="name"
              id="name"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              onInvalid={(e) =>
                e.target.setCustomValidity("Ad və soyad qeyd edin")
              }
              onChange={(e) => {
                e.target.setCustomValidity("");
              }}
            />
            <label
              htmlFor="name"
              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Ad Soyad*
            </label>
          </div>

          <div className="relative z-0 w-full mb-5 group">
            <label
              for="email-address-icon"
              class="block text-sm font-medium text-gray-400"
            >
              Telefon
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 top-[-2px] start-0 flex items-center pointer-events-none">
                <span className="text-sm">+994</span>
              </div>
              <input
                type="text"
                pattern="\d*"
                maxLength={10}
                name="phone"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                  e.target.setCustomValidity("");
                }}
                onInvalid={(e) =>
                  e.target.setCustomValidity("Telefon nömrəsi qeyd edin")
                }
                id="phone"
                required
                className=" border-0 border-b-2 text-sm border-gray-300 text-gray-900 outline-none focus:ring-blue-500 focus:border-blue-500 block w-full ps-9 p-2.5"
              />
            </div>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="text"
              name="note"
              id="note"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              defaultValue={""}
            />
            <label
              htmlFor="note"
              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Sifariş üçün qeyd (vacib deyil)
            </label>
          </div>

          <div className="flex gap-5 text-[#003F25] relative py-6">
            <img
              src={productImages[0]?.IMAGE_URL}
              className="w-24 h-auto"
              alt="cover"
            />
            <div className="flex flex-col md:flex-row justify-between gap-5 w-full">
              <div>
                {product.GROSS ? (
                  <p className="py-1 px-3 bg-[#C10000] text-white text-xs w-fit font-semibold">
                    -40%
                  </p>
                ) : (
                  ""
                )}

                <p className="text-sm">{product.NAME}</p>
                <div className="flex gap-2 items-center">
                  <p className="font-semibold">{product.NET}AZN</p>
                  {product.GROSS ? (
                    <p className="text-xs line-through text-red-800">
                      {product.GROSS}AZN
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex h-fit">
                <button
                  className="w-10 border border-slate-300"
                  type="button"
                  onClick={() => {
                    if (count > 1) {
                      setCount(count - 1);
                    } else {
                      return;
                    }
                  }}
                >
                  -
                </button>
                <input
                  type="number"
                  value={count}
                  className="w-10 border-t border-b border-slate-300 outline-none text-xs p-1 text-center"
                />
                <button
                  className="w-10 border border-slate-300"
                  onClick={() => setCount(count + 1)}
                  type="button"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <button
            className="capitalize text-sm text-white bg-[#003F25] px-3 py-2.5 font-semibold w-full"
            type="submit"
          >
            Sifarişi tamamla
          </button>
        </form>
        <img
          src={"/close.svg"}
          alt="close"
          className="cursor-pointer absolute top-2 right-2 w-6 h-auto"
          onClick={() => setIsOpen(false)}
        />
      </div>
    </ReactModal>
  );
};

export default OrderNowModal;
