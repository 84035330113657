import { Link } from "react-router-dom";
import { useSite } from "../../context/SiteContext";

const MobileDropdown = ({
  label,
  subcategories,
  isOpenDropdown,
  toggle,
  id,
}) => {
  const data = subcategories.filter((item) => item.CATEGORY_ID === id);
  const { setMobileNavIsOpen } = useSite();
  return (
    <div className="">
      <div className="flex justify-between items-center hover:bg-slate-200 py-2 px-4">
        <button
          className="w-full text-left  focus:outline-none"
          onClick={toggle}
        >
          {label} <i className="fa fa-caret-down float-right pr-2"></i>
        </button>
        <img src="/arrow-right.svg" alt="arrow" width={15} />
      </div>

      <div
        className={`bg-white ${
          isOpenDropdown ? "block" : "hidden"
        } transition-all`}
      >
        {data.map((item, index) => (
          <Link
            to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
            key={index}
            className="block text-black py-2 px-4 hover:bg-slate-200"
            onClick={() => setMobileNavIsOpen(false)}
          >
            {item.SUBCATEGORY}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MobileDropdown;
