import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSite } from "../../context/SiteContext";
import sumCartTotal from "../utils/sumCartTotal";
import { toast } from "react-toastify";
import Button from "../utils/Button";
import OrderNowModal from "../Modal/OrderNowModal";
import clsx from "clsx";
import { fetchProductImages } from "../../services/image_service";

const Product = ({ data, size = "regular" }) => {
  const [productImages, setProductImages] = useState([]);
  const [bgImage, setBgImage] = useState("");
  const [cartStatus, setCartStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    setCartCount,
    setProductsOnCart,
    productsOnCart,
    setTotalCart,
    productsOnFavorite,
    setProductsOnFavorite,
    setFavCount,
    favCount,
  } = useSite();

  const handleMouseEnter = () => {
    setBgImage(productImages.find((im) => im.TYPE !== 1)?.IMAGE_URL);
  };
  const [favStatus, setFavStatus] = useState(
    productsOnFavorite.some((item) => item.id === data.id)
  );

  const handleMouseLeave = () => {
    setBgImage(productImages.find((im) => im.TYPE === 1)?.IMAGE_URL);
  };

  const addToCart = () => {
    if (data.STOCK >= 1) {
      const dubl = productsOnCart.find((item) => item.data.ID === data.ID);
      if (!dubl) {
        setProductsOnCart((prev) => [...prev, { data, count: 1 }]);
        setCartCount((prev) => prev + 1);
        setTotalCart(sumCartTotal([...productsOnCart, { data, count: 1 }]));
        localStorage.setItem(
          "cartItem",
          JSON.stringify([...productsOnCart, { data, count: 1 }])
        );
        toast.info("Məhsul səbətə əlavə edildi!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.info("Məhsul səbətdə mövcuddur!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setCartStatus(true);
    } else {
      toast.error("Bu məhsul stokda yoxdur!");
    }
  };
  const addToFavorite = () => {
    if (!favStatus) {
      setProductsOnFavorite((prev) => [...prev, data]);
      localStorage.setItem(
        "favItem",
        JSON.stringify([...productsOnFavorite, data])
      );
      setFavCount(favCount + 1);
    } else {
      setProductsOnFavorite((prev) =>
        prev.filter((item) => item.ID !== data.ID)
      );
      localStorage.setItem(
        "favItem",
        JSON.stringify(productsOnFavorite.filter((item) => item.ID !== data.ID))
      );
      setFavCount(favCount - 1);
    }
    setFavStatus(!favStatus);
  };

  async function getProductImages() {
    const images = await fetchProductImages();
    const current = images.filter((item) => item.PRODUCT_ID === data.ID);
    setProductImages(current);
    const coverImage = current.find((im) => im.TYPE === 1);
    setBgImage(coverImage.IMAGE_URL);
  }

  useEffect(() => {
    getProductImages();
  }, []);

  return (
    <div
      className={clsx({
        "flex flex-col justify-between animate__animated animate__fadeIn": true,
        "w-full sm:w-[250px] relative": size === "regular",
        "w-full sm:w-[220px] relative": size === "small",
      })}
    >
      <div>
        <Link to={`/products/${data.SLUG}`}>
          <div
            className={clsx({
              "sm:w-[250px] h-[250px] w-full bg-cover bg-center relative transition-all duration-500":
                size === "regular",
              "sm:w-[220px] h-[220px] w-full bg-cover bg-center relative transition-all duration-500":
                size === "small",
            })}
            style={{
              backgroundImage: `url(${bgImage})`,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          ></div>
        </Link>

        {data.GROSS ? (
          <span className="text-xs py-1 px-2 bg-[#C10000] text-white absolute left-2 top-2">
            Endirimdə
          </span>
        ) : (
          ""
        )}
        {favStatus ? (
          <img
            src={"/heart-red.svg"}
            alt="icon"
            className="absolute right-2 top-2 cursor-pointer w-6 h-auto"
            onClick={addToFavorite}
          />
        ) : (
          <img
            src={"/heart.svg"}
            alt="icon"
            className="absolute right-2 top-2 cursor-pointer w-6 h-auto"
            onClick={addToFavorite}
          />
        )}
        <div className="mt-2 text-gray-700">
          <div className="font-bold flex items-center gap-2 mb-1">
            <span>{data.NET}AZN</span>
            {data.GROSS ? (
              <span className="text-xs line-through text-red-800">
                {data.GROSS}AZN
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-col gap-1">
            <Link to={`/products/${data.SLUG}`}>
              <h5 className="font-bold text-[#003F25]">{data.NAME}</h5>
            </Link>
            <p className="text-xs text-[#565353]">{data.SUMMARY}</p>
          </div>
        </div>
      </div>

      <div className="flex gap-2 mt-3 ">
        {cartStatus ? (
          <p className="uppercase text-xs border border-[#003F25] text-[#003F25] px-3 py-1 font-semibold">
            Səbətdə
          </p>
        ) : (
          <Button
            title={"səbətə at"}
            type={"secondary"}
            handleClick={addToCart}
          />
        )}

        <Button
          title={"İndi al"}
          type={"primary"}
          handleClick={() =>
            data.STOCK >= 1
              ? setIsOpen(true)
              : toast.error("Bu məhsul stokda yoxdur!")
          }
        />
      </div>
      <OrderNowModal isOpen={isOpen} setIsOpen={setIsOpen} product={data} />
    </div>
  );
};

export default Product;
