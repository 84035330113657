import axios from "axios";

export async function fetchBlogs() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`);
    return response.data;
  } catch (error) {}
}

export async function fetchRecommedBlogs() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/blogs/recommed`
    );
    return response.data;
  } catch (error) {}
}
export async function fetchBlogBySlug(url) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/blogs/slug/${url}`
    );
    return response.data;
  } catch (error) {}
}
