import { ToastContainer } from "react-toastify";
import Header from "../components/Header/Header";
import { SiteProvider } from "../context/SiteContext";
import Footer from "../components/Footer/Footer";
import MobileNav from "../components/Nav/MobileNav";

const RootLayout = ({ children }) => {
  return (
    <div>
      <SiteProvider>
        <Header />
        {/* <MobileNav /> */}

        {children}
        <Footer />
      </SiteProvider>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default RootLayout;
