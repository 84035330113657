import { Link } from "react-router-dom";

const BlogItem = ({ data }) => {
  const maxLength = 90;

  if (data.SUMMARY.length > maxLength) {
    data.SUMMARY = data.SUMMARY.substring(0, maxLength - 3).concat("...");
  }

  return (
    <div
      className="sm:w-60 w-full animate__animated animate__fadeIn flex flex-col justify-between h-fit"
      key={data.ID}
    >
      <div>
        <Link to={`/blogs/${data.SEO_URL}`}>
          <h3 className="font-semibold">{data.NAME}</h3>
          <div
            style={{
              backgroundImage: `url(${data.IMAGE_PATH})`,
            }}
            id="blog-item"
            className="sm:w-[250px] w-full h-[250px] sm:h-[150px] my-4 rounded bg-cover bg-center"
          ></div>
        </Link>
        <p className="text-[#999b9c] mb-2 text-xs">{data.SUMMARY}</p>
      </div>

      <Link to={`/blogs/${data.SEO_URL}`}>
        <button className="capitalize text-sm rounded-sm text-[#003F25] font-bold hover:text-[#edeae3] hover:bg-[#003F25] transition-all bg-[#edeae3] px-3 py-1 w-[170px] h-[50px] items-center justify-between flex">
          OXU <span>{">"}</span>
        </button>
      </Link>
    </div>
  );
};

export default BlogItem;
