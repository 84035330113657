import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchBlogBySlug } from "../services/blog_service";
import parse from "html-react-parser";
import BlogSidebar from "../components/Blog/BlogSidebar";
import Loader from "../components/utils/Loader";
import SEO from "../components/utils/SEO";

const BlogDetailPage = () => {
  const { url } = useParams();
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState([]);

  async function getBlogs() {
    const data = await fetchBlogBySlug(url);
    setBlog(data);
    setLoading(false);
  }

  useEffect(() => {
    getBlogs();
  }, [url]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <SEO title={blog?.NAME} />

          <h1 className="text-3xl mb-4 font-semibold">{blog?.NAME}</h1>
          <div className="flex gap-4 blog-main md:flex-row flex-col">
            <div className="blog-content">
              <img src={blog?.IMAGE_PATH} alt="blog-cover" className="w-full" />
              <span className="text-sm float-right py-3">
                {new Date(blog?.DATE).toLocaleDateString()}
              </span>
              <p className="py-10">
                {blog?.CONTENT ? parse(blog?.CONTENT) : ""}
              </p>
              <div className="flex gap-2 justify-center mb-10">
                <a
                  href="https://www.facebook.com/profile.php?id=100071805180682"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={"/facebook.svg"} alt="facebook" className="w-10" />
                </a>
                <a
                  href="https://www.instagram.com/gemstone.mirvarist/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={"/instagram.svg"}
                    alt="instagram"
                    className="w-10"
                  />
                </a>
              </div>
            </div>
            <BlogSidebar />
          </div>
        </div>
      )}
    </>
  );
};

export default BlogDetailPage;
