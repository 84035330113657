import React, { useEffect, useState } from "react";
import { useSite } from "../../context/SiteContext";
import sumCartTotal from "../utils/sumCartTotal";
import { fetchProductImages } from "../../services/image_service";

const CartProduct = ({ item }) => {
  const [count, setCount] = useState(item.count);
  const [productImages, setProductImages] = useState([]);
  const { productsOnCart, setProductsOnCart, setTotalCart, setCartCount } =
    useSite();

  function increaseCount() {
    setCount(count + 1);
    const prod = structuredClone(productsOnCart);
    prod.forEach((product) => {
      if (product.data.ID === item.data.ID) {
        product.count = count + 1;
      }
    });
    setCartCount(prod.reduce((acc, value) => acc + value.count, 0));
    setProductsOnCart(prod);
    localStorage.setItem("cartItem", JSON.stringify(prod));
    setTotalCart(sumCartTotal(prod));
  }

  function decreaseCount() {
    if (!(count === 1)) {
      setCount(count - 1);
      const prod = structuredClone(productsOnCart);
      prod.forEach((product) => {
        if (product.data.ID === item.data.ID) {
          product.count = count - 1;
        }
      });
      setCartCount(prod.reduce((acc, value) => acc + value.count, 0));
      setTotalCart(sumCartTotal(prod));
      localStorage.setItem("cartItem", JSON.stringify(prod));
    }
  }

  function removeProductFromCart() {
    const filteredProducts = productsOnCart.filter(
      (pr) => pr.data.ID !== item.data.ID
    );
    setCartCount(filteredProducts.reduce((acc, value) => acc + value.count, 0));
    setProductsOnCart(filteredProducts);
    setTotalCart(sumCartTotal(filteredProducts));
    localStorage.setItem("cartItem", JSON.stringify(filteredProducts));
  }

  async function getProductImages() {
    const data = await fetchProductImages();
    const current = data.filter((pr) => pr.PRODUCT_ID === item.data.ID);
    setProductImages(current);
  }

  useEffect(() => {
    getProductImages();
  }, []);
  return (
    <div className="flex gap-5 text-[#003F25] relative">
      <img
        src={productImages[0]?.IMAGE_URL}
        className="w-24 h-auto"
        alt="cover"
      />
      <div className="flex flex-col sm:flex-row justify-between gap-5 w-full">
        <div>
          {item.data.GROSS ? (
            <p className="py-1 px-3 bg-[#C10000] text-white text-xs w-fit font-semibold">
              -40%
            </p>
          ) : (
            ""
          )}

          <p className="text-sm">{item.data.NAME}</p>
          <div className="flex gap-2 items-center">
            <p className="font-semibold">{item.data.NET}AZN</p>
            {item.data.GROSS ? (
              <p className="text-xs line-through text-red-800">
                {item.data.GROSS}AZN
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex h-fit mr-12">
          <button
            className="w-10 border border-slate-300"
            onClick={decreaseCount}
          >
            -
          </button>
          <input
            type="number"
            value={count}
            className="w-10 border-t border-b border-slate-300 outline-none text-xs p-1 text-center"
          />
          <button
            className="w-10 border border-slate-300"
            onClick={increaseCount}
          >
            +
          </button>
        </div>
      </div>
      <img
        src={"/close.svg"}
        alt="close"
        className="cursor-pointer absolute right-3 top-1 w-4 h-auto"
        onClick={removeProductFromCart}
      />
    </div>
  );
};

export default CartProduct;
