import axios from "axios";
import { toast } from "react-toastify";

export async function fetchBanners() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/banners`
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data);
  }
}
