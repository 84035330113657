import React from "react";
import { useSite } from "../context/SiteContext";
import Product from "../components/Product/Product";
import SEO from "../components/utils/SEO";

const FavoritePage = () => {
  const { productsOnFavorite } = useSite();

  return (
    <div className="container">
      <SEO title={"Seçilmişlər"} />

      <div className="flex gap-5 text-[#003F25] pb-5">
        <h3 className="font-bold text-2xl">Seçilmişlər</h3>
      </div>
      {productsOnFavorite.length > 0 ? (
        <>
          <div className="flex gap-10 justify-center mt-4 pt-10 pb-16 flex-wrap">
            {productsOnFavorite.map((item) => (
              <Product key={item.id} data={item} />
            ))}
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center gap-2 pt-10 pb-16">
          <img
            src={"/heart.svg"}
            alt="cart"
            className="opacity-10 w-24 h-auto"
          />
          <p className="text-slate-400">Seçilmişlərdə heç bir məhsul yoxdur</p>
        </div>
      )}
    </div>
  );
};

export default FavoritePage;
