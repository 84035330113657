import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProductBySubcategory } from "../services/product_service";
import { useSite } from "../context/SiteContext";
import ProductList from "../components/Product/ProductList";
import SideProductFilter from "../components/Product/SideProductFilter";
import ProductOrder from "../components/Product/ProductOrder";
import SEO from "../components/utils/SEO";

const SubCategoryPage = () => {
  const params = useParams();
  const { subcategories } = useSite();
  const [products, setProducts] = useState([]);
  const subcat =
    subcategories.find(
      (item) => item.SUB_SLUG === params.sub && item.CAT_SLUG === params.parent
    ) || "";
  async function getProducts(id) {
    const data = await fetchProductBySubcategory(id);
    setProducts(data);
  }

  useEffect(() => {
    if (subcat && subcat.ID) {
      getProducts(subcat.ID);
    }
  }, [subcat]);

  return (
    <div className="container mb-16">
      <SEO title={subcat?.SUBCATEGORY?.toUpperCase()} />
      <div className="flex justify-between items-center py-10 sm:flex-row flex-col gap-5 sm:gap-0">
        <h1 className="font-bold text-2xl">
          {subcat?.SUBCATEGORY?.toUpperCase() || ""}
        </h1>
        <ProductOrder />
      </div>
      <div className="flex gap-10 relative flex-col md:flex-row ">
        <SideProductFilter />

        <ProductList products={products} subcat={subcat} type={"sub"} />
      </div>
    </div>
  );
};

export default SubCategoryPage;
