import { useSearchParams } from "react-router-dom";
import { useSite } from "../../context/SiteContext";
import { useEffect, useState } from "react";

const SideProductFilter = () => {
  const { subcategories } = useSite();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedStones, setSelectedStones] = useState(
    searchParams.get("stone")?.split(",") || []
  );

  const stones = subcategories.filter((item) => item.CATEGORY_ID === 4);

  function handleFilter(e) {
    if (e.target.value !== "default") {
      const unique = new Set([...selectedStones, e.target.value]);
      setSelectedStones(Array.from(unique));
    }
  }

  function removeSelected(value) {
    setSelectedStones(selectedStones.filter((prev) => prev !== value));
  }

  useEffect(() => {
    if (selectedStones.length > 0) {
      const params = Object.fromEntries([...searchParams]);
      params.stone = selectedStones.join(",");
      setSearchParams(params);
    } else {
      const params = Object.fromEntries([...searchParams]);
      params.stone = [];
      setSearchParams(params);
    }
  }, [selectedStones]);

  return (
    <div className="p-5 border border-slate-100 h-fit w-full md:w-fit min-w-60">
      <label htmlFor="countries" className="block mb-2 text-lg font-medium">
        Təbii daşlar
      </label>
      <select
        id="stones"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
        defaultValue={"default"}
        onChange={handleFilter}
      >
        <option value={"default"}>Siyahıdan seç</option>
        {stones.map((item, index) => (
          <option key={index} value={item.SUBCATEGORY}>
            {item.SUBCATEGORY}
          </option>
        ))}
      </select>
      <div className="flex flex-col gap-1 mt-5">
        {selectedStones.map((item, index) => (
          <div key={index} className="flex justify-between items-center">
            <p>{item}</p>
            <img
              src="/close.svg"
              alt="close"
              width={13}
              className="cursor-pointer"
              onClick={() => removeSelected(item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideProductFilter;
