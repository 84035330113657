import { useParams } from "react-router-dom";
import { useSite } from "../context/SiteContext";
import { useEffect, useState } from "react";
import ProductOrder from "../components/Product/ProductOrder";
import SideProductFilter from "../components/Product/SideProductFilter";
import ProductList from "../components/Product/ProductList";
import { fetchProductBySubcategoryMerge } from "../services/product_service";
import SEO from "../components/utils/SEO";

const CategoryPage = () => {
  const params = useParams();
  const { subcategories } = useSite();
  const [products, setProducts] = useState([]);
  const subcat =
    subcategories.find((item) => item.SUB_SLUG === params.parent) || "";

  async function getProducts(name) {
    const data = await fetchProductBySubcategoryMerge(name);
    setProducts(data);
  }

  useEffect(() => {
    if (params.parent) {
      getProducts(params.parent);
    }
  }, [subcat]);
  return (
    <div className="container mb-16">
      <SEO title={subcat?.SUBCATEGORY?.toUpperCase()} />

      <div className="flex justify-between items-center py-10 sm:flex-row flex-col gap-5 sm:gap-0">
        <h1 className="font-bold text-2xl">
          {subcat?.SUBCATEGORY?.toUpperCase() || ""}
        </h1>
        <ProductOrder />
      </div>
      <div className="flex gap-10 relative flex-col md:flex-row ">
        <SideProductFilter />
        <ProductList products={products} subcat={subcat} />
      </div>
    </div>
  );
};

export default CategoryPage;
