import React from "react";
import { Link } from "react-router-dom";

const LinkItem = ({ title, handleMouseEnter }) => {
  return (
    <>
      <Link
        to={"#"}
        className="font-bold hover:border-b-2 pb-1 border-[#003F25] capitalize"
        onMouseEnter={handleMouseEnter}
      >
        {title}
      </Link>
    </>
  );
};

export default LinkItem;
