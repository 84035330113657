import React from "react";
import { Link } from "react-router-dom";

const MenuDropdown = ({ menuItems, hoverOutHandle, size }) => {
  function getDropdown(params) {
    if (size === "small") {
      return (
        <ul className="flex flex-col gap-3 p-4 text-sm">
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link
                to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
                className="capitalize"
              >
                {item.SUBCATEGORY}
              </Link>
            </li>
          ))}
        </ul>
      );
    } else if (size === "large") {
      return (
        <ul className="flex gap-8 p-4 text-sm">
          <div className="flex flex-col gap-3">
            {menuItems.slice(0, 10).map((item, index) => (
              <li key={index}>
                <Link
                  to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
                  className="capitalize"
                >
                  {item.SUBCATEGORY}
                </Link>
              </li>
            ))}
          </div>
          <div className="flex flex-col gap-3">
            {menuItems.slice(10, 20).map((item, index) => (
              <li key={index}>
                <Link
                  to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
                  className="capitalize"
                >
                  {item.SUBCATEGORY}
                </Link>
              </li>
            ))}
          </div>
          <div className="flex flex-col gap-3">
            {menuItems.slice(20, 30).map((item, index) => (
              <li key={index}>
                <Link
                  to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
                  className="capitalize"
                >
                  {item.SUBCATEGORY}
                </Link>
              </li>
            ))}
          </div>
          <div className="flex flex-col gap-3">
            {menuItems.slice(40, 50).map((item, index) => (
              <li key={index}>
                <Link
                  to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
                  className="capitalize"
                >
                  {item.SUBCATEGORY}
                </Link>
              </li>
            ))}
          </div>
          <div className="flex flex-col gap-3">
            {menuItems.slice(50, 60).map((item, index) => (
              <li key={index}>
                <Link
                  to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
                  className="capitalize"
                >
                  {item.SUBCATEGORY}
                </Link>
              </li>
            ))}
          </div>
          <div className="flex flex-col gap-3">
            {menuItems.slice(60, 70).map((item, index) => (
              <li key={index}>
                <Link
                  to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
                  className="capitalize"
                >
                  {item.SUBCATEGORY}
                </Link>
              </li>
            ))}
          </div>
          <div className="flex flex-col gap-3">
            {menuItems.slice(70, 78).map((item, index) => (
              <li key={index}>
                <Link
                  to={`/categories/${item.CAT_SLUG}/${item.SUB_SLUG}`}
                  className="capitalize"
                >
                  {item.SUBCATEGORY}
                </Link>
              </li>
            ))}
          </div>
        </ul>
      );
    }
  }

  return (
    <div
      className="absolute bg-white w-full top-9 z-10"
      onMouseLeave={hoverOutHandle}
    >
      {getDropdown()}
    </div>
  );
};

export default MenuDropdown;
