import React from "react";
import CoverSection from "../components/Cover/CoverSection";
import CaruselSection from "../components/Section/CaruselSection";
import SEO from "../components/utils/SEO";
const HomePage = () => {
  return (
    <>
      <SEO title={"Gemstone | Hər növ təbii daşların satışı və sifarişi"} />

      <section className="min-h-[370px]">
        <CoverSection />
      </section>
      <CaruselSection title={"Boyunbağı"} category={"boyunbagi"} />
      <CaruselSection title={"Qolbaq"} category={"qolbaq"} />
      <CaruselSection title={"Sırğa"} category={"sirga"} />
      <CaruselSection title={"Üzük"} category={"uzuk"} />
      <CaruselSection title={"Dəst"} category={"dest"} />
      <CaruselSection title={"Təsbeh"} category={"tesbeh"} />
    </>
  );
};

export default HomePage;
