import React from "react";

const Footer = () => {
  return (
    <div className="text-[#003F25] container py-10 flex flex-col border-t border-slate-100">
      <div className="flex flex-col md:flex-row gap-10 md:gap-0 justify-between">
        <div className="max-w-[500px]">
          <h4 className="font-bold">Haqqımızda</h4>
          <p className="text-sm text-justify">
            Gemstone.az Azərbaycanda təbii və sintetik qiymətli daşların
            satışını və sifarişlə istehsalını həyata keçirən mağazadır. Biz hər
            adda və növdə təbii daşlardan hazırlanmış və zövqlə dizayn edilmiş
            müxtəlif zinət əşyaları, həmçinin, qeyri-adi hədiyyəlik əşyaların
            satışı ilə məşğuluq. <br />
            Gemstone.az <strong>“İncistan” MMC-yə </strong>məxsus bir brenddir.
          </p>
        </div>
        <div className="flex flex-col items-center gap-3">
          <div className="font-bold text-sm flex flex-col">
            <a href="tel:94999979955">+994 99 997 99 55</a>
            <a href="mailto:info@gemstone.az" target="_blank" rel="noreferrer">
              info@gemstone.az
            </a>
          </div>
          <div className="flex gap-3">
            <a
              href="https://wa.me/994999979955"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7 h-auto" alt="social" src={"/whatsapp.svg"} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100071805180682"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7 h-auto" alt="social" src={"/facebook.svg"} />
            </a>
            <a
              href="https://www.instagram.com/gemstone.mirvarist/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7 h-auto" alt="social" src={"/instagram.svg"} />
            </a>
          </div>
        </div>
      </div>

      <div className="self-center text-sm mt-10">
        <p>
          Bütün hüquqlar qorunur. ©{new Date().getFullYear()}
          <a
            style={{
              fontWeight: 600,
              marginLeft: "0.5rem",
            }}
            href="https://agshin.dev"
          >
            <span>agshin</span>
            <span
              style={{
                color: "#05CC05",
              }}
            >
              /dev
            </span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
