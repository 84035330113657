import { useEffect, useState } from "react";
import { fetchBanners } from "../../services/banner_service";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const CoverSection = () => {
  const [banners, setBanners] = useState([]);

  async function getBanner(params) {
    const data = await fetchBanners();
    setBanners(data);
  }

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <Splide
      options={{
        type: "loop",
        gap: "1rem",
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
      }}
    >
      {banners &&
        banners.map((item) => (
          <SplideSlide key={item.ID}>
            <div
              className="animate__animated animate__fadeIn w-full keen-slider__slide "
              key={item.ID}
            >
              <div className="flex lg:gap-0 md:gap-4 md:flex-row flex-col gap-6">
                <div
                  className="w-full h-80 bg-cover max-w-3xl"
                  style={{
                    backgroundImage: `url(${item?.IMAGE_PATH})`,
                  }}
                ></div>
                <div className="flex justify-center items-center w-full bg-gray-50">
                  <div className="text-left max-w-xl px-5 sm:px-0">
                    <h2 className="text-xl uppercase font-bold mb-2">
                      {item?.TITLE}
                    </h2>
                    <p className="">{item?.DESCRIPTION}</p>
                  </div>
                </div>
              </div>
              <div className="bg-[#003F25] p-2 mt-2 md:mt-0">
                <h4 className="text-sm text-white text-center">
                  Şəhərdaxili çatdırılma pulsuzdur
                </h4>
              </div>
            </div>
          </SplideSlide>
        ))}
    </Splide>
  );
};

export default CoverSection;
