import { useSite } from "../../context/SiteContext";
import { fetchSendOrderEmail } from "../../services/email_service";
import { fetchPostOrder } from "../../services/order_service";
import { useNavigate } from "react-router-dom";

const OrderForm = () => {
  const {
    totalCart,
    productsOnCart,
    setProductsOnCart,
    setOrderLoading,
    setCartCount,
  } = useSite();
  const navigate = useNavigate();

  async function submitOrder(e) {
    e.preventDefault();
    setOrderLoading(true);
    const form = new FormData(e.target);
    const order = {
      products: productsOnCart,
      fullname: form.get("name"),
      phone: "+994" + form.get("phone"),
      note: form.get("note"),
    };
    const res = await fetchPostOrder(order, process.env.REACT_APP_ORDER_TOKEN);
    if (res) {
      await fetchSendOrderEmail({
        name: order.fullname,
        phone: order.phone,
        code: res,
        note: order.note,
        total: totalCart,
        products: productsOnCart,
        token: process.env.REACT_APP_ORDER_TOKEN,
      });
      setTimeout(() => {
        setOrderLoading(false);
        setProductsOnCart([]);
        setCartCount(0);
        localStorage.clear("cartItem");
        navigate(`/orders/${res}`);
      }, 1500);
    } else {
      setOrderLoading(false);
    }
  }

  return (
    <form onSubmit={submitOrder} method="post" className="mb-4">
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="name"
          id="name"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          onInvalid={(e) => e.target.setCustomValidity("Ad və soyad qeyd edin")}
          onChange={(e) => {
            e.target.setCustomValidity("");
          }}
        />
        <label
          htmlFor="name"
          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Ad Soyad*
        </label>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <label
          for="email-address-icon"
          class="block text-sm font-medium text-gray-400"
        >
          Telefon
        </label>
        <div class="relative">
          <div class="absolute inset-y-0 top-[-2px] start-0 flex items-center pointer-events-none">
            <span className="text-sm">+994</span>
          </div>
          <input
            type="text"
            pattern="\d*"
            maxLength={10}
            name="phone"
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
              e.target.setCustomValidity("");
            }}
            onInvalid={(e) =>
              e.target.setCustomValidity("Telefon nömrəsi qeyd edin")
            }
            id="phone"
            required
            className="bg-gray-50 border-0 border-b-2 text-sm border-gray-300 text-gray-900 outline-none focus:ring-blue-500 focus:border-blue-500 block w-full ps-9 p-2.5"
          />
        </div>
      </div>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="note"
          id="note"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          defaultValue={""}
        />
        <label
          htmlFor="note"
          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Sifariş üçün qeyd (vacib deyil)
        </label>
      </div>
      <div className="border border-black p-5 flex flex-col gap-8">
        <p className="flex justify-between text-[#003F25]">
          <span className="font-semibold">Cəmi məbləğ:</span>{" "}
          <span className="font-semibold">{totalCart}AZN</span>
        </p>
        <button className="py-1 px-2 text-white bg-black" type="submit">
          Sifarişi tamamla
        </button>
      </div>
    </form>
  );
};

export default OrderForm;
