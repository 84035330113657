import axios from "axios";
import { toast } from "react-toastify";

export async function fetchSendOrderEmail(data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/email/success`,
      data
    );
    return true;
  } catch (error) {
    toast.error(error.response.data);
    return false;
  }
}
