import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
        <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
          <div className="relative">
            <div className="absolute flex flex-col justify-center w-full">
              <div className="">
                <img src="/logo.svg" alt="logo" className="w-64 mx-auto" />

                <h1 className="my-2 text-[#003F25] font-bold text-2xl">
                  Nə isə düzgün getmədi...
                </h1>
                <p className="my-2 text-[#003F25]">
                  Axtardığın səhifə tapılmadı.
                </p>
                <button
                  type={"button"}
                  onClick={() => navigate("/")}
                  className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-[#003F25] text-white hover:bg-[#01321e] focus:outline-none focus:ring-2 focus:ring-[#01321e] focus:ring-opacity-50"
                >
                  Ana Səhifə
                </button>
              </div>
            </div>
            <div>
              <img src="https://i.ibb.co/G9DC8S0/404-2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
